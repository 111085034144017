<template>
  <div class="contents userMgt users">
    <div class="title flexB">
      <h2>회원 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option disabled selected value>선택</option>
          <option value="username">이름</option>
          <option value="email">이메일</option>
          <option value="mobileNo">연락처</option>
          <option value="status">상태</option>
          <!-- <option value="planner">플래너</option> -->
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <!-- <div class="flex">
        <p class="bold">카테고리</p>
        <label>
          <input type="radio" value="" checked v-model="category" />전체
        </label>
        <label>
          <input type="radio" value="STAMP" v-model="category" />
          스탬프 투어
        </label>
        <label>
          <input type="radio" value="FAIR" v-model="category" />
          더 플래너 웨딩페어
        </label>
      </div> -->
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">회원 리스트 ({{ total }}명)</h2>
      <table>
        <colgroup>
          <col style="width: 6%" />
          <col style="width: 8%" />
          <col style="width: 8%" />
          <col style="width: 15%" />
          <col style="width: 5%" />
          <col style="width: 5%" />
          <col style="width: 8%" />
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>권한</th>
          <th>이름</th>
          <th>상태</th>
          <th>상담</th>
          <th>문의</th>
          <th>플래너</th>
          <th>스탬프 투어</th>
          <th>가입경로</th>
          <th>가입경로2</th>
          <th>가입일</th>
        </tr>

        <tr v-for="(data, i) in userList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>
            {{
              data.isAdmin && data.adminType == "SUPER"
                ? "슈퍼 관리자"
                : data.isAdmin && data.adminType == "ADVISOR"
                ? "관리자+플래너"
                : data.isAdmin && data.adminType == "MANAGER"
                ? "일반 관리자"
                : data.isAdmin && data.adminType == "PLANNER"
                ? "플래너"
                : "일반"
            }}
          </td>
          <td>
            <router-link to @click.native="handleDetail(data._id)">{{
              data.dropedAt ? "탈퇴회원" : data.username
            }}</router-link>
          </td>
          <td>{{ data.status == "TBD" ? "" : data.status }}</td>
          <td>{{ data.consult.length }}</td>
          <td>{{ data.qna.length }}</td>
          <td>{{ data.planner ? data.planner.plannerName : "" }}</td>
          <td>{{ data.tours.length }}</td>
          <td>{{ data.userType }}</td>
          <td>{{ data.signupPath2 }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm:ss") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="userList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { deleteUser } from "@/api/index";
import { fetchUserList, findPlannerByUserId } from "@/api/admin";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";
export default {
  mixins: [format],
  name: "UserManage",
  components: { NoData },

  data() {
    return {
      moment: moment,
      category: "",
      inputMode: "",
      searchWord: "",
      userList: [],
      total: 0,
      currentPage: 1,
      isNodata: false,
      adminType: "",
      plannerId: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "1");
  },
  beforeMount() {
    this.adminType = localStorage.getItem("adminType");
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    handleDetail(id) {
      this.$router.push({ name: "userDetail", query: { id: id } });
    },
    handleDeleteUser(id) {
      this.$confirm("회원을 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteUser(id).then((res) => {
          if (res.status == 200) {
            this.getUserList();
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.userList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getUserList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserList();
    },
    async getUserList() {
      if (this.adminType == "PLANNER" || this.adminType == "ADVISOR") {
        await findPlannerByUserId().then((res) => {
          if (res.data.status == 200) {
            this.plannerId = res.data.data ? res.data.data._id : "";
          }
        });
      }

      let params = {
        page: this.currentPage,
        inputMode: this.inputMode,
        searchWord: this.searchWord,
        category: this.category,
      };
      if (this.adminType == "PLANNER" || this.adminType == "ADVISOR") {
        if (this.plannerId == "") {
          return this.$alert("플래너 정보를 등록해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          }).then(() => {
            this.$router.push("/admin/plannermanage/register");
          });
        } else {
          params.plannerId = this.plannerId;
        }
      }
      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.userList = res.data.users;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
          this.currentPage = res.data.page;
        } else if (res.data.status == 409) {
          this.$router.push("/");
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
